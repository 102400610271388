<template>
  <div class="flex flex-col items-center">
    <!-- Hero section with background image, heading, subheading and button -->
    <div
      class="relative overflow-hidden bg-cover bg-no-repeat w-full hero-bg h-56">
      <div
        class="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed"
        style="background-color: rgba(255, 255, 255, 0.15)">
      </div>
    </div>
    <div class="w-full">
      <div class="flex h-full items-center justify-start m-auto max-w-7xl">
        <div class="relative -mt-16 px-2 py-4">
          <div
            class="w-96 block rounded bg-white p-6 dark:bg-neutral-700 multi-shadow">
            <h1 class="font-semibold text-2xl p-4">Member Login</h1>
          </div>
        </div>
      </div>
    </div>
    <!-- Hero section with background image, heading, subheading and button -->
    <div class="max-w-5xl w-full">
      <!-- <h2 class="text-2xl font-semibold p-4 border-b-2 border-red-600">
        Member Login
      </h2>

      <img
        src="../assets/img/seminar_member_macbook.jpg"
        alt="Compensation Benchmarking Banner"
        class="object-cover w-full lg:h-96 h-80 mt-4"
      > -->

      <div class="p-4 mb-20 mt-10">
        <form class="flex flex-col bg-white px-8 pt-5 pb-4 sm:p-6 sm:pb-4 md:w-128 w-96
        mx-auto">
          <div class="py-2 px-4">
            <label for="email" class="block text-sm font-normal leading-5 text-gray-700"
              ><span class="text-red-600">*</span> Email</label
            >
            <input
              type="email"
              autocomplete="email"
              @keyup.enter="signIn"
              @animationstart="checkAnimation"
              @focus="emailFocus"
              id="email"
              name="email"
              required="true"
              v-model="email"
              class="mt-1 form-input block w-full py-2 px-3 border border-gray-400
                    shadow-sm focus:outline-none focus:shadow-outline-blue
                    focus:border-blue-400 hover:border-gray-500 transition duration-150 ease-in-out
                    sm:text-sm sm:leading-5 bg-gray-100"
            />
            <div v-show="emailEmpty" class="pt-1 text-xs text-red-600">
              Please enter email address
            </div>
          </div>
          <div class="py-2 px-4">
            <label for="password" class="block text-sm font-normal leading-5 text-gray-700"
              ><span class="text-red-600">*</span> Password</label
            >
            <input
              @keyup.enter="signIn"
              @focus="passwordFocus"
              id="password"
              type="password"
              v-model="password"
              class="mt-1 form-input block w-full py-2 px-3 border border-gray-400
                    shadow-sm focus:outline-none focus:shadow-outline-blue
                    focus:border-blue-400 hover:border-gray-500 transition duration-150 ease-in-out
                    sm:text-sm sm:leading-5 bg-gray-100"
            />
            <div v-show="passwordEmpty" class="pt-1 text-xs text-red-600">
              Please enter your password
            </div>
          </div>
          <div class="pb-2 px-4">
            <a v-show="!autofilled" class="text-xs underline text-gray-700" href="/sign/forget">
              Forget Password?
            </a>
          </div>
          <div v-show="signInError" class="px-4 text-red-600 text-sm">
            <span v-if="signInError == 'max attempt'">
              You’ve reached the maximum logon attempts. Your account has been locked.
              Please contact (65) 6235 2852 for more information.
            </span>
            <span v-else>
              {{ signInError }}
            </span>
          </div>
          <button
            v-show="!emailEmpty && !passwordEmpty"
            @click="signIn()"
            type="button"
            class="mx-4 mt-4 mb-4 justify-center py-2 px-4
                  border border-transparent
                  text-sm leading-5 font-medium text-white bg-red-600
                  hover:bg-red-500 focus:outline-none focus:border-red-700
                  focus:shadow-outline-red active:bg-red-700
                  transition duration-150 ease-in-out"
          >
            Log in
          </button>
          <div
            v-show="emailEmpty || passwordEmpty"
            class="mx-4 mt-4 mb-4 justify-center text-center py-2 px-4
                  border border-transparent
                  text-sm leading-5 font-medium text-white bg-gray-600
                  hover:bg-gray-500 focus:outline-none focus:border-gray-700
                  focus:shadow-outline-gray active:bg-gray-700
                  transition duration-150 ease-in-out">
            Please fill-in email & password
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>
<style>
input:-webkit-autofill {
  animation-name: onAutoFillStart;
}

:not(input:-webkit-autofill) {
  animation-name: onAutoFillCancel;
}

@keyframes onAutoFillStart {
  from {
  }
  to {
  }
}
@keyframes onAutoFillCancel {
  from {
  }
  to {
  }
}
</style>

<script>
import store from '@/store/index';
import { accessRights } from '../api/private/rpcs';
import { signInCall } from '../api/public/rpcs';

export default {
  data() {
    return {
      autofilled: false,
      email: '',
      password: '',
      signInError: '',
    };
  },
  methods: {
    checkAnimation(e) {
      const actionName = e.animationName;
      if (actionName.includes('onAutoFillStart')) {
        this.autofilled = true;
      } else if (actionName.includes('onAutoFillCancel')) {
        this.autofilled = false;
      }
    },
    emailFocus() {
      if (this.email === 'email') {
        this.email = '';
      }
    },
    passwordFocus() {
      if (this.password === 'password') {
        this.password = '';
      }
    },
    signIn() {
      this.signInError = '';
      signInCall({
        userid: this.email,
        password: this.password,
      })
        .then(() => {
          accessRights().then((data) => {
            store.user = data;
            if (store.signInGoTo) {
              this.$router.push('/online_services');
              store.signInGoTo = '';
            } else {
              this.$router.push('/online_services');
            }
          });
        })
        .catch((err) => {
          if (err.message.startsWith('Unexpected token P')) {
            this.signInError = 'System not available. Please try again later.';
            return;
          }
          this.signInError = err.message;
        });
    },
  },
  computed: {
    emailEmpty() {
      return !this.email || this.email.trim().length === 0;
    },
    passwordEmpty() {
      return !this.password || this.password.trim().length === 0;
    },
    user() {
      return store.user;
    },
  },
  created() {
    accessRights()
      .then((data) => {
        store.user = data;
        this.$router.push('/online_services');
      })
      .catch(() => {
        if (this.$route.matched.some((record) => record.meta.requiresAuth)) {
          this.$router.replace('/member');
        }
      });
  },
  watch: {
    autofilled(val) {
      if (val) {
        this.email = 'email';
        this.password = 'password';
      }
    },
  },
};
</script>

<style>
.hero-bg {
  background: url('../assets/img/seminar_member_macbook.jpg') center;
  background-size: cover;
  background-repeat: no-repeat;
}
.b4footer-bg {
  background: url('../assets/img/background/testimonial.jpg') top;
}
.multi-shadow {
  /* box-shadow: -10px 10px 0px rgba(33, 33, 33, 0.7), -20px 20px 0px rgba(33, 33, 33, 0.4), -30px 30px 0px rgba(33, 33, 33, 0.1); */
  box-shadow:
  5px 5px 0 0 rgba(36, 11, 54,0.3),
  10px 10px 0 0 rgba(241, 39, 17,0.2),
  15px 15px 0 0 rgba(195, 20, 50,0.1);
}
</style>
